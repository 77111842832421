@import url('https://fonts.googleapis.com/css2?family=Spline+Sans+Mono:ital,wght@0,300..700;1,300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Spline Sans Mono", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.main .page {
  scroll-snap-align: start;
  overflow-y: scroll;
}

.header {
  transition: all 0.5s ease;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.gallery {
  scroll-behavior: smooth;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.project-tile {
  display: flex;
  flex-direction: column;
  scroll-snap-align: center;
}

#light {
  transition: all 1s ease;
  background-color: #FCC88F;
  /* background-color: #FFF; */
}

#dark {
  transition: all 1s ease;
  background-color: #10193C;
  /* background-color: #000; */
}

#light .header {
  background-color: #FCC88F;
  box-shadow: 0px 0px 40px 40px #FCC88F; 
}
#dark .header {
  background-color: #10193C;
  box-shadow: 0px 0px 40px 40px #10193C;
}


.navbar {
  transition: all 1s ease;
}

.navbar a{
  transition: all 0.2s ease;
}

#toggle {
  min-width: 150px;
}

#dark .mode-toggle {
  content:url("./assets/MOON.png");
}
#light .mode-toggle {
  content:url("./assets/SUN.png");
}
#dark #currentMode:after {
  content: "dark theme";
}
#light #currentMode:after {
  content: "light theme";
}

#dark .clickable {
  background-color: #1C295C;
}

#dark .clickable:hover {
  background-color: #10193C;
}

#light .clickable {
  background-color: #FCBD77;
}

#light .clickable:hover {
  background-color: #FCC88F;
}

#pageContact a {
  transition: all 0.2s ease;
}

@layer base {
  #dark * {
    @apply text-white;
  }
  #light * {
    @apply text-black;
  }
  #dark .mode-toggle {
  }
  #light .mode-toggle {
  }
  .page {
    @apply h-dvh pt-40 pb-10 snap-center;
  }
  .clickable {
    @apply border-2 border-b-4 border-black rounded-2xl p-4
  }
  h1 {
    @apply text-3xl md:text-4xl lg:text-5xl;
  }
  h2 {
    @apply text-xl md:text-2xl lg:text-4xl;
  }
  h3 {
    @apply text-base md:text-lg lg:text-2xl;
  }
}